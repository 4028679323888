import router, { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { getRoute, PATH } from '@/utils/routes';
import { getNavigationPagesList, toggleRfqFormModal } from '@/redux/actions';
import { useEffect, useState, useRef, useCallback } from 'react';
import ReactHamburger from '@/components/Common/ReactHamburger';
import LoginComponent from '@/components/LoginAndDropdownComponent';
import { IS_DESKTOP } from '@/utils/checkClientDeviceType';
import getNavList from '@/utils/getNavList';
import { showMegaMenu } from '@/utils/showMegaMenu';
import { deviceWidth } from '@/utils/deviceWidth';
import { getStoreType } from '@/utils/getStoreType';
import { CommonHeaderIconsMobile } from '../CommonHeaderIcons';
import CollectionHeaderDropdown from '../CollectionHeaderDropdown';
import { HeaderHome } from '@/assets/svgExports/HeaderHome';
import { HeaderInfoIcon } from '@/assets/svgExports/HeaderInfoIcon';
import { StoreNameComp } from '../StoreNameComponent';
import ShopComponent from '../ShopComponent';
import useSaveHeaderHeight from '../../../../hooks/useSaveHeaderHeight';
import { STORE_CUSTOMIZATION } from '@/utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';
import MegaMenuDesktopView from '@/components/MegaMenu/MegaMenuDesktopView';

const Header10 = (props) => {
  const isDesktop = deviceWidth > 786;
  const ref = useRef();
  const route = useRouter();
  const dispatch = useDispatch();

  const [open, setOpen] = useState({
    openShop: false,
    openMore: false,
    openDropdown: false,
  });
  const [openMegaMenu, setOpenMegaMenu] = useState({
    desktop: false,
    mobile: false,
  });
  const [openCollections, setOpenCollections] = useState(false);
  const { storeData, navigationList, collections } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    navigationList: state.additionalPagesReducer.navigationList,
    collections: state.catalogReducer.collections,
  }));

  const navItemsState = getNavList(navigationList);

  const { items, user } = useSelector((state) => ({
    items: state.cartReducer.items,
    user: state.userReducer,
  }));

  const headerBgColor = props?.config?.color_scheme?.bg_color || '#FFFFFF';

  const headerRef = useRef();
  useSaveHeaderHeight(headerRef);

  useEffect(() => {
    if (!open.openDropdown) setOpenCollections(false);
  }, [open.openDropdown]);

  useEffect(() => {
    if (storeData?.store_id && !navigationList?.length) {
      dispatch(
        getNavigationPagesList({
          storeId: storeData?.store_id,
          isPublished: true,
        })
      );
    }
  }, [storeData?.store_id, navigationList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (isDesktop) {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      setOpen((state) => ({ ...state, openShop: false, openMore: false }));
    }
  };

  const clickForRoute = (route) => {
    router.push(getRoute(route, storeData?.store_info?.domain));
  };

  const handleCartClick = () => {
    if (getStoreType(storeData) === STORE_CUSTOMIZATION.B2B) {
      dispatch(toggleRfqFormModal());
      return;
    }
    clickForRoute(PATH.CART);
  };

  const menuItemClick = (nav) => {
    if (openMegaMenu?.desktop || openMegaMenu?.mobile) {
      setOpenMegaMenu(() => ({
        desktop: false,
        mobile: false,
      }));
    }

    if (nav?.page_type === 'blank_page' && nav?.is_external_url) {
      window.open(nav.slug_url, '_blank');
      return;
    }

    if (nav.slug_url === 'shop') {
      if (document.querySelector('#CollectionCategoryProductWidget')) {
        document.querySelector('#CollectionCategoryProductWidget').scrollIntoView();
      } else {
        router.push(getRoute(PATH.PRODUCT, storeData?.store_info?.domain));
      }
    } else if (nav.slug_url === 'subNavList') {
      setOpen((state) => ({
        ...state,
        openMore: !open.openMore,
      }));
    } else {
      if (nav.is_editable) {
        window.location.href = getRoute(
          PATH.additionalPage(nav.slug_url),
          storeData?.store_info?.domain
        );
      } else {
        router.push(getRoute(nav.slug_url, storeData?.store_info?.domain));
      }
    }
  };

  const toggleDropdown = () => {
    setOpen((state) => ({
      ...state,
      openDropdown: !state.openDropdown,
    }));
  };

  const routeMatchForActiveLink = (url) => {
    return `/[domain]${url ? `/${url}` : ``}` === route.pathname
      ? 'menu-item-active'
      : '';
  };

  const closeShopDropdown = useCallback(
    () => setOpen((state) => ({ ...state, openShop: false, openDropdown: false })),
    []
  );

  const navSubList = (nav) => {
    return (
      <div className="cfe-dropdown-nav" ref={ref}>
        <div className="cfe-dropdown-nav__items">
          {nav.map((n) => (
            <div
              className="cfe-dropdown-nav__item"
              key={n.page_id}
              onClick={() => menuItemClick(n)}
            >
              {n.page_name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleShopClick = (page, dropdownNav) => {
    if (showMegaMenu()) {
      IS_DESKTOP
        ? setOpenMegaMenu(() => ({
            mobile: false,
            desktop: !openMegaMenu.desktop,
          }))
        : setOpenMegaMenu(() => ({
            desktop: false,
            mobile: !openMegaMenu.mobile,
          }));
    } else {
      if (collections?.length) {
        if (dropdownNav) {
          setOpenCollections(true);
        } else {
          menuItemClick(page);
        }
      } else {
        setOpen((state) => ({ ...state, openShop: true, openMore: false }));
      }
    }
  };

  const renderNavType = (page, dropdownNav = false) => {
    switch (page.slug_url) {
      case '':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                <div className="mr3 visible-mobile">
                  <HeaderHome
                    color={
                      storeData?.theme?.colors?.primary_color !== '#ffffff'
                        ? '#000000'
                        : '#ffffff'
                    }
                  />
                </div>
                <span>{page.page_name}</span>
              </div>
            </div>
          </li>
        );
      case 'shop':
        return (
          <ShopComponent
            ref={ref}
            page={page}
            dropdownNav={dropdownNav}
            handleShopClick={handleShopClick}
            setOpenMegaMenu={setOpenMegaMenu}
            open={open}
            closeShopDropdown={closeShopDropdown}
            showMegaMenuMobile={openMegaMenu}
            bgColor={headerBgColor}
          />
        );
      case 'subNavList':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                <span>More</span>
              </div>
              {page?.subNavs?.length > 0 && (
                <img
                  src="/assets/images/angleright.png"
                  alt="dropdown button"
                  style={{
                    transform: 'rotate(90deg)',
                    width: 7,
                    marginLeft: dropdownNav ? '0px' : '8px',
                  }}
                />
              )}
            </div>
            {open.openMore && navSubList(page.subNavs)}
          </li>
        );
      case 'about-us':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                <div className="mr3 visible-mobile">
                  <HeaderInfoIcon
                    color={
                      storeData?.theme?.colors?.primary_color !== '#ffffff'
                        ? '#000000'
                        : '#ffffff'
                    }
                  />
                </div>
                <span>{page.page_name}</span>
              </div>
            </div>
          </li>
        );
      default:
        return (
          <li
            key={page.page_id}
            className={`menu-item ${routeMatchForActiveLink(page.slug_url)}`}
            onClick={() => menuItemClick(page)}
          >
            {page.page_name}
          </li>
        );
    }
  };

  const searchBar = () => (
    <div
      className="search-comp flex items-center justify-center pointer w-100"
      onClick={() => clickForRoute(PATH.SEARCH)}
    >
      <img src="/assets/images/search-black.png" className="mr2" />
      <span className="ttu">Search</span>
    </div>
  );

  const leftSection = () => (
    <>
      <div className="visible-desktop-flex items-center">
        <StoreNameComp showLogo />
      </div>
      <div className="visible-mobile-flex w-100">
        <ReactHamburger
          open={open.openDropdown}
          setOpen={toggleDropdown}
          theme="#000000"
        />
      </div>
    </>
  );

  const middleSection = () => {
    return <div className="visible-desktop-flex">{searchBar()}</div>;
  };

  return (
    <header ref={headerRef} className="header10 collection-header">
      <div className="header10__top-component">
        {leftSection()}
        {middleSection()}
        <section className="flex items-center justify-end icon-set visible-desktop">
          <span>
            <LoginComponent>
              <img className="icon-set-image" src="/assets/images/user.png" />
              {user?.isLogin ? (
                <img
                  alt="dropdown button"
                  src="/assets/images/down-arrow-thin.svg"
                  className="ml2 arrow-icon"
                />
              ) : (
                ''
              )}
            </LoginComponent>
          </span>
          <span onClick={handleCartClick}>
            <img className="icon-set-image" src="/assets/images/cart.png" />
            {items?.length > 0 && (
              <span className="cart-items absolute z-2">{items.length}</span>
            )}
          </span>
        </section>
        <div className="visible-mobile">
          <CommonHeaderIconsMobile />
        </div>
      </div>
      <div className="header10__bottom-component w-100 visible-desktop">
        <div className="visible-desktop-flex w-100 justify-center">
          {navItemsState?.length > 0 && (
            <ul className="header10__bottom-component--menu">
              {navItemsState?.map((nav) => renderNavType(nav, true))}
            </ul>
          )}
        </div>
      </div>
      {openMegaMenu.desktop && (
        <MegaMenuDesktopView
          open={openMegaMenu.desktop}
          onClose={() => setOpenMegaMenu({ desktop: false, mobile: false })}
        />
      )}
      {open.openDropdown && (
        <CollectionHeaderDropdown
          renderNavType={renderNavType}
          navItemsState={navItemsState}
          openCollections={openCollections}
          goBack={() => setOpenCollections(false)}
          collectionListType={props?.config?.name || null}
        />
      )}
    </header>
  );
};

export default Header10;
